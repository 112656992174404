
              @import "@/assets/css/variables.scss";
            

























































































































































// @import '@/assets/css/pages/myProfile.scss';
.personal_cont {
  .title {
    font-size: 16px;
    color: #09090D;
    line-height: 24px;
    font-weight: 700;
  }

  @include screen-mobile {
    .el-col:nth-child(1) {
      .cont {
        margin-top: 24px;
      }
    }
  }

  .cont {
    padding: 16px;
    background: $background-color-base;
    border-radius: 8px;
    margin-top: 16px;

    .top {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .svg-icon {
        font-size: 24px;
        @include rtl-sass-prop(margin-right, margin-left, 8px);
      }

      span {
        font-size: 16px;
        color: $text-primary;
        line-height: 24px;
        font-weight: 700;
      }
    }

    .bottom {
      font-size: 14px;
      color: $text-secondary;
      line-height: 22px;
      @include rtl-sass-prop(margin-left, margin-right, 32px);

      &.no_bind {
        color: #ACACBF;
      }
    }

    &.tip_cont {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        margin-right: 4px;
      }
    }
  }
}
